<template>
  <div>
    <el-tag class="tag">认领超时时间 <span style="font-size: 12px">自工单创建时间起始</span></el-tag>
    <table class="tb" cellspacing="0" cellpadding="0" style="border-collapse:collapse;">
      <tr>
        <td class="tbtd"></td>
        <td class="tbtd">超时时间(单位分钟)</td>
        <td class="tbtd">通知对象</td>
      </tr>
      <tr>
        <td class="tbtd">一级认领超时时间</td>
        <td class="tbtd"><el-input-number v-model="model.assignedTimeoutTime1" style="width: 100%"></el-input-number></td>
        <td class="tbtd">工单所在班组的队长</td>
      </tr>
      <tr>
        <td class="tbtd">二级认领超时</td>
        <td class="tbtd"><el-input-number v-model="model.assignedTimeoutTime2" style="width: 100%"></el-input-number></td>
        <td class="tbtd">队长的上级</td>
      </tr>
      <tr>
        <td class="tbtd">三级认领超时</td>
        <td class="tbtd"><el-input-number v-model="model.assignedTimeoutTime3" style="width: 100%"></el-input-number></td>
        <td class="tbtd">队长的上级的上级</td>
      </tr>
    </table>
    <el-tag class="tag">完成超时时间 <span style="font-size: 12px">自工单到达时间起始</span></el-tag>
    <table class="tb" cellspacing="0" cellpadding="0" style="border-collapse:collapse;">
      <tr>
        <td class="tbtd"></td>
        <td class="tbtd">超时时间(单位分钟)</td>
        <td class="tbtd">通知对象</td>
      </tr>
      <tr>
        <td class="tbtd">一级完成超时时间</td>
        <td class="tbtd"><el-input-number v-model="model.completedTimeoutTime1" style="width: 100%"></el-input-number></td>
        <td class="tbtd">工单所在班组的队长</td>
      </tr>
      <tr>
        <td class="tbtd">二级完成超时</td>
        <td class="tbtd"><el-input-number v-model="model.completedTimeoutTime2" style="width: 100%"></el-input-number></td>
        <td class="tbtd">队长的上级</td>
      </tr>
      <tr>
        <td class="tbtd">三级完成超时</td>
        <td class="tbtd"><el-input-number v-model="model.completedTimeoutTime3" style="width: 100%"></el-input-number></td>
        <td class="tbtd">队长的上级的上级</td>
      </tr>
    </table>
    <el-button v-if="$auth('急修保存')" style="margin-top: 10px" type="primary" @click="submit">保存</el-button>
    <!--    <el-tag class="tag">通知对象</el-tag>-->
    <!--    <table class="tb" cellspacing="0" cellpadding="0" style="border-collapse:collapse;">-->
    <!--      <tr>-->
    <!--        <td class="tbtd"></td>-->
    <!--        <td class="tbtd">超时时间</td>-->
    <!--        <td class="tbtd">通知对象</td>-->
    <!--      </tr>-->
    <!--      <tr v-for="(item,index) in data.timeout" :key="index">-->
    <!--        <td class="tbtd">{{item.rule}}</td>-->
    <!--        <td class="tbtd">{{item.date}}</td>-->
    <!--        <td class="tbtd">{{item.object}}</td>-->
    <!--      </tr>-->
    <!--    </table>-->
  </div>
</template>

<script>
  export default {
    name: "RepairNotice",
    data() {
      return {
        model:{

        },
      };
    },
    mounted() {
      this.getData();
    },
    methods:{
      getData() {
        this.$http.get("/order-timeout-config/repair").then(res=>{
          this.model=res;
        });
      },
      submit() {
        this.$http.post("/order-timeout-config/repair",this.model).then(()=>{
          this.$message.success("保存成功");
          this.getData();
        });
      },
    },
  };
</script>

<style scoped>
.tag{
  font-size: 20px;
  margin: 5px 0;
  text-align: center;
  color: black;
  font-weight: bolder;
  border: none;
  background-color: white;
}
.tbtd{
  width: 400px;
  height: 20px;
  border: 1px solid black;
}
</style>