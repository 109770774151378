<template>
  <el-tabs active-name="first" @tab-click="change">
    <el-tab-pane v-if="$auth('工单超时配置急修')" label="急修" name="first"><repair-notice ref="repair"></repair-notice></el-tab-pane>
    <el-tab-pane v-if="$auth('工单超时配置保养')" label="保养" name="second"><maintenanceNotice ref="maintenance"></maintenanceNotice></el-tab-pane>
  </el-tabs>
</template>

<script>
  import RepairNotice from "@/views/maintenance/RepairNotice";
  import maintenanceNotice from "@/views/maintenance/maintenanceNotice";
  export default {
    components:{RepairNotice,maintenanceNotice},
    methods:{
      change() {
        this.$refs.repair.getData();
        this.$refs.maintenance.getData();
      },
    },
  };
</script>

<style scoped>
.title{
  width: 50px;
  text-align: center;
  border: 1px solid black;
}
.content{
  width: 100px;
  text-align: center;
  border: 1px solid black;
}
.tbtd{
  width: 400px;
  height: 20px;
  border: 1px solid black;
}
</style>